import { HODashboardConfig } from '@client/store/types/homeowner';
import { LOCardTypes } from '@client/store/types/loan-officer';
import { CustomizationDataJSONInterface } from '@hc/consumer-customization-data';
import { FilterKey } from '../filter-constants';

/**
 * Identifiers used to drive hardcoded differences in branding or functionality within the app.
 * These identifiers match the subdomain in the URL hostname (i.e. "www" in "www.comehome.com")
 *
 * Note that many more cobrand ids exist within each environment, created and maintained using Parcon.
 *
 * When adding a new cobrand id here, also add it to hcfe COMEHOME_COBRAND_IDS constant
 * (https://github.com/housecanary/hcfe/blob/main/src/constants/comehome.constants.ts#L2)
 * so that new subdomain will be added to your ssl cert and hosts file.
 */
export const COBRAND_IDS = {
  CHASE: 'chase' as 'chase',
  CALIBERHOMELOANS: 'caliberhomeloans' as 'caliberhomeloans',
  /* This is the "ComeHome" cobrand.  Referred to by its subdomain, "www" */
  WWW: 'www' as 'www',
  LENDCO: 'lendco' as 'lendco',
  LENDINGTREE: 'lendingtree' as 'lendingtree',
  TOPAGENTSRANKED: 'topagentsranked' as 'topagentsranked',
  /* This is the "Wells Fargo" cobrand.  Referred to by its subdomain, "shopforhomes" */
  SHOPFORHOMES: 'shopforhomes' as 'shopforhomes',
  /* SHOPFORHOMESLO and SFHTWO are used as testing environments for Wells */
  SHOPFORHOMESLO: 'shopforhomeslo' as 'shopforhomeslo',
  SFHTWO: 'sfhtwo' as 'sfhtwo',
  WELLSFARGO: 'wellsfargo' as 'wellsfargo',
};

/**
 * Used in forEachCobrand method to generate unit tests, snapshot tests.
 * For cobrands like foamortgage, topagentsranked, tests should be written for custom components
 */
export const COBRAND_IDS_FOR_TESTS = {
  CHASE: 'chase' as 'chase',
  LENDCO: 'lendco' as 'lendco',
  WWW: 'www' as 'www',
};

export const COBRAND_NAMES_FOR_TESTS = {
  [COBRAND_IDS_FOR_TESTS.CHASE]: 'Chase' as 'Chase',
  [COBRAND_IDS_FOR_TESTS.LENDCO]: 'LendCo' as 'LendCo',
  [COBRAND_IDS_FOR_TESTS.WWW]: 'ComeHome' as 'ComeHome',
};

export type FontFace = {
  src: {
    url: string;
    format: string;
    isHostedAsset?: boolean;
  }[];
  weight: number;
  style: string;
};

export type GrantProgramConfigForView = {
  program_name: string;
  header: string;
  subheader: string;
  list_header: string;
  bullet_1: string;
  bullet_2: string;
  bullet_3: string;
  button_text_and_url: {
    button_text: string;
    destination_url: string;
    native_app_nav_key?: string;
  };
  disclaimer_label?: string | null;
  disclaimer_content?: string | null;
};

export type GrantProgramConfig = {
  gp_header_link: {
    button_text: string;
    destination_url: string;
    native_app_nav_key?: string;
  };
  gp_search_results_modal: GrantProgramConfigForView;
  gp_property_details_banner: GrantProgramConfigForView;
  gp_srp_property_card_grant_program_label: string | null;
};

export type BreakoutSectionsConfig = {
  section_id: string;
  section_content_ids: string[];
};

export type SSOModalConfig = {
  specialUserTypeSsoCtaText: string | null;
  specialUserTypeSsoCtaUrl: string | null;
  specialUserTypeSsoCtaButtonBgColor: string | null;
  specialUserTypeSsoCtaButtonTextColor: string | null;
  ssoAuthModalBodyText: string | null;
};

export type PropertyDetailsPageConfig = {
  bannerButtonLabel: string | null;
  bannerDestinationUrl: string | null;
  bannerLoUrlTemplate: string | null;
  bannerHeadline: string | null;
  claimHomeHeader: string | null;
  claimHomeSubHeader: string | null;
  claimHomeCTALabel: string | null;
  secondaryCtaText: string | null;
  secondaryCtaUrl: string | null;
  secondaryCtaButtonBgColor: string | null;
  secondaryCtaButtonTextColor: string | null;
  secondaryCtaNativeAppNavKey: string | null;
  shareModalLegalLanguage: string | null;
  showRentalEstimate: boolean | null;
  breakoutSections: BreakoutSectionsConfig | null;
  iconCtaText: string | null;
  iconCtaUrl: string | null;
};

export type WatchlistPageConfig = {
  savedHomesLabel?: string | null;
  savedHomesSubtext?: string | null;
  savedHomesIconUrl?: string | null;
  savedSearchesLabel?: string | null;
  savedSearchesSubtext?: string | null;
  alertExplanationText?: string | null;
  savedSearchesDescription?: string | null;
  watchlistActionButtonSavedIcon?: string | null;
  watchlistActionButtonUnsavedIcon?: string | null;
};

export type SearchFiltersConfig = FilterKey[];

export type SearchResultsPageConfig = {
  saveSearchButtonIcon?: string | null;
  saveSearchButtonLabel?: string | null;
  showRentalEstimate?: boolean | null;
  searchFiltersOrder?: SearchFiltersConfig | null;
  propertyCardGrantProgramLabel?: string | null;
};

/**
 * To add a new key/value pair to the customization data, please follow these steps
 *
 * 1. In the consumer-customization-data repo
 * Follow steps in README,
 * to add a new key/value pair in the default customization data.
 * https://git.housecanary.net/Engineering/consumer-customization-data
 *
 * 2. In consumer-web repo
 *  - Add a type of a new key in the CustomizationData interface in src/client/store/types/cobranding.ts
 * 3. You can override the default customization in the Custom Configurations field on Parcon.
 */

export type CustomizationData = CustomizationDataJSONInterface;

export type CustomizationDataPartial = Partial<CustomizationData>;

export interface BaseDomainTheme {
  displayName: string;
  authScheme: string;
  primaryColor: string;
  secondaryColor: string;
  textOnPrimary: string;
  textOnWhite: string;
  linkOnPrimary: string;
  linkOnWhite: string;
  lightGreyColor: string;
  semiLightGreyColor: string;
  midGreyColor: string;
  darkGreyColor: string;
  whiteColor: string;
  almostBlackColor: string;
  accessibilityFocusOutlineColor: string;
  mapPlaceBoundaryLayerLineColor: string;
  fontFaces: Array<FontFace> | null;
  logo: string | null;
  favicon: string;
  googleAnalyticsId: string | null;
  googleTagManagerId: string | null;
  homeSiteUrl: string | null;
  backToSiteText: string | null;
  primaryCtaColor: string;
  secondaryCtaColor: string;
  primaryButtonFillColor: string;
  primaryButtonBorderRadius: string;
  secondaryButtonFillColor: string;
  secondaryButtonBorderRadius: string;
  genericLOInfo?: {
    phone: string | null;
    email: string | null;
    nmlsId: string | null;
  };
  lenderCtasConfig?: LenderCtasConfig;
  loCardConfig?: LoConfig;
  avmBackgroundColor: string;
  mobileLogo: string | null;
  headerBackgroundColor: string;
  headerLinkColor: string;
  headerLinkHoverColor: string;
  navLinkSearchLabel: string | null;
  navLinkHomeownerLabel: string | null;
  navLinkWatchlistLabel: string | null;
  navLinkAlertsLabel: string | null;
  svgFillColor: string;
  supportUrl: string | null;
  headerLinks:
    | null
    | {
        url: string;
        text: string;
      }[];
  hoDashboardConfig: HODashboardConfig | null;
  footer: {
    logoUrl: string;
    text: string;
  };
  disclosureText: {
    estimatedPayment: string;
    mortgageCalculator: string;
    refiCalculator: string;
  };
  yourTeam?: {
    lo_title: string;
    lo_no_lo_text: string;
    lo_no_lo_url: string;
    lo_cta_1: string;
    lo_cta_2: string;
    lo_cta_3: string;
    lo_cta_4: string;
    lo_cta_5: string;
    agent_cta_1: string;
    agent_cta_2: string;
    agent_cta_3: string;
    agent_cta_4: string;
    agent_cta_5: string;
    button_text: string | null;
    button_icon: string | null;
  };
  customDisclaimers: {
    estimated_value_disclaimer_label: string;
    estimated_value_disclaimer_content: string;
    mortgage_calculator_disclaimer_label: string;
    mortgage_calculator_disclaimer_content: string;
  };
  grantProgram: GrantProgramConfig;
  contactBrokerage: {
    headerText: string;
    headerBgColor: string;
    subheaderText: string;
    ctaHeaderText: string;
    ctaSubheaderText: string;
    ctaButtonText: string;
    ctaButtonTextColor: string;
    ctaButtonBgColor: string;
    ctaUrl: string;
    ctaNativeAppNavKey: string;
  };
  pdp: PropertyDetailsPageConfig;
  watchlist: WatchlistPageConfig;
  searchResultsPage: SearchResultsPageConfig;
  homeownerWelcomePage: HOWelcomePageConfig;
  ssoModal?: SSOModalConfig;
  chaseQuickSearchNoResultsNativeAppNavKey: string | null;
  homePage: HomepageCustomizations;
}

interface HOWelcomePageConfig {
  hoWelcomeHeaderText: string | null;
  hoWelcomeSearchPlaceholder: string | null;
  hoWelcomeLoginLabel: string | null;
  hoWelcomeClaimToolsHeader: string | null;
  hoWelcomeImproveValueLabel: string | null;
  hoWelcomeImproveValueIcon: string | null;
  hoWelcomeCompareValueLabel: string | null;
  hoWelcomeCompareValueIcon: string | null;
  hoWelcomeLeverageValueLabel: string | null;
  hoWelcomeLeverageValueIcon: string | null;
  hoWelcomeViewPriceTrendsLabel: string | null;
  hoWelcomeViewPriceTrendsIcon: string | null;
}

interface WidgetConfig {
  authorized_hosts: string[];
  background_image: string;
  banner_background_color: string;
  destination: string;
  headline_copy: string;
  key: string;
  layout: string;
  leaving_site_disclaimer_copy: string;
}

export type WidgetConfigs = WidgetConfig[] | null;

export interface DomainTheme extends BaseDomainTheme {
  customizationData: CustomizationData;
  widget: WidgetConfigs;
}

export type CustomHeaderCtaConfig = {
  label: string;
  url: string;
};

export type LenderCtasConfig = {
  srp: {
    headline: string;
    headlineFontColor: string;
    headlineBackgroundColor: string;
    ctaText: string;
    ctaUrl: string;
    buttonColor: string;
    buttonFontColor: string;
    loUrlTemplate: string | null;
    nativeAppNavKey: string | null;
  };
  pdp: {
    ctaText: string;
    ctaUrl: string;
    buttonColor: string;
    buttonFontColor: string;
    loUrlTemplate: string | null;
    nativeAppNavKey: string | null;
  };
  ho: {
    headline: string;
    headlineFontColor: string;
    headlineBackgroundColor: string;
    ctaText: string;
    ctaUrl: string;
    buttonColor: string;
    buttonFontColor: string;
    loUrlTemplate: string | null;
    nativeAppNavKey: string | null;
  };
  hic: {
    headline: string;
    headlineFontColor: string;
    headlineBackgroundColor: string;
    ctaText: string;
    ctaUrl: string;
    buttonColor: string;
    buttonFontColor: string;
    loUrlTemplate: string | null;
    nativeAppNavKey: string | null;
  };
  ho_alt: {
    headline: string | null;
    ctaText: string | null;
    ctaUrl: string | null;
    buttonColor: string | null;
    buttonFontColor: string | null;
    loUrlTemplate: string | null;
    nativeAppNavKey: string | null;
  };
  headerCtas: CustomHeaderCtaConfig[];
};

export type LoConfig = {
  hbSrp: {
    enableIntroText: boolean;
    introText: string;
    introAltText: string;
    headline: string;
    buttonColor: string;
  };
  hbPdp: {
    enableIntroText: boolean;
    introText: string;
    introAltText: string;
    headline: string;
    buttonColor: string;
    bgColor: string;
    cta?: string;
    url?: string;
    urlTemplate?: string;
    cardType?: LOCardTypes;
  };
  hoDashboard: {
    enableIntroText: boolean;
    introText: string;
    introAltText: string;
    headline: string;
    buttonColor: string;
    bgColor: string;
  };
  hoHic: {
    enableIntroText: boolean;
    introText: string;
    introAltText: string;
    headline: string;
    buttonColor: string;
    bgColor: string;
  };
};

export type CustomDomainTheme = {
  primaryColor: string;
  secondaryColor: string;
  textOnPrimary: string;
  textOnWhite: string;
  linkOnPrimary: string;
  linkOnWhite: string;
};

export type ActiveAddressSearchLabelPosition = { left: string; top: string };
type savedSearchCardButtonStyle = { marginLeft?: string; width?: string };

export type StyleDefMapping = {
  accessibilityFocusOutlineStyle: string;
  accessibilityFocusOutlineWidth: string;
  accessibilityFocusOutlineColor: string;
  accessibilityFocusColor: string;
  activeAddressSearchLabelPosition: ActiveAddressSearchLabelPosition;
  activeBuyingPowerColor: string;
  activeFilterCountColor: string;
  activeFilterPillColor: string;
  activeNavItemBorderBottomStyle: string;
  activeNavItemColor: string;
  activeOptionUnderlineColor: string;
  activePrimaryColor: string;
  activeRadioButtonColor: string;
  activitiesActiveOptionUnderlineColor: string;
  agentContactPhoneIconColor: string;
  alertsCardValueInlineFontWeight: number;
  alertMailIconMobileColor: string;
  appHeadingBackgroundColor: string;
  authButtonBackgroundColor: string;
  authButtonHoverColor: string;
  authModalHeadingBackground: string;
  autoCompleteDropdownColor: string;
  avmBreakdownBackground: string;
  avmBreakdownBorderColor: string;
  avmBreakdownBorderRadius: string;
  avmBreakdownBoxShadow: string;
  avmDeepDiveFactorBorderColor: string;
  breakoutAndPreviewSectionsTitleFontSize: string;
  breakoutAndPreviewSectionsTitleFontWeight: number;
  buttonBackgroundSecondary: string;
  buttonDefaultTextColor: string;
  cantFindAddressModalClickableLogoStyle: { height: string; width: string };
  chatSurveyInputBg: string;
  chatSurveyPrimary: string;
  chatSurveyResponseBg: string;
  checkboxCheckedColor: string;
  choChartDollarIconFill: string;
  choHeaderBgBase: string;
  choHeroHomePageBgGradientOverlay: string;
  choHeroHomePageLinkColor: string;
  choHeroHomePageSearchBoxLogInLinkColor: string;
  choHeroHomePageSearchButtonBackground: string;
  choHomeImprovementEstimatedValueAmountBackground: string;
  choHomeImprovementHeroContentTextColor: string;
  choHomeImprovementHeroTitleFontWeight: number;
  choHomeImprovementHeroTitleTextColor: string;
  choLineChartStrokeColor: string;
  choProFinderCheckBg: string;
  choProFinderResultsBorderRadius: string;
  choProFinderResultsButtonColor: string;
  choProFinderResultsLinkColor: string;
  circleButtonActiveBackground: string;
  circleButtonInactiveBackground: string;
  confirmEmailButtonBg: string;
  dismissableBannerBackground: string;
  dismissableBannerTextColor: string;
  dismissableBannerLinkColor: string;
  defaultTextColor: string;
  dropdownListOptionSelected: string;
  editProfileLinkColor: string;
  favicon: string;
  filterButtonHighLight: string;
  findAgentButtonColor: string;
  findAgentPageBgGradientOverlay: string;
  findAgentPageLinkColor: string;
  flatButtonColor: string;
  forecastBackgroundColorOne: string;
  forecastBackgroundColorThree: string;
  forecastBackgroundColorTwo: string;
  forecastChartGradientColorOne: string;
  forecastChartGradientColorTwo: string;
  headerBackgroundColor: string;
  homeImprovementCalculatorSqFtInputButtonColor: string;
  homeImprovementCalculatorSqFtInputButtonHoverColor: string;
  homeImprovementToggleHomeConditionListArrowColor: string;
  homeownerAdBg: string;
  homeownerAdBtnRadius: string;
  homeownerAdTextColor: string;
  homeownerAvmBackground: string;
  homeownerBorderColor: string;
  homeownerCardHeading: string;
  homeownerCardRefiCardBg: string;
  homeownerCardRefiCardTxt: string;
  homeownerCardRefiDescription: string;
  homeownerCardRefiDividerColor: string;
  homeownerCardRefiTitle: string;
  homeownerEditDetailsBtnColor: string;
  homeownerEditDetailsColor: string;
  homeownerHelocBtnBg: string;
  homeownerHelocBtnTxt: string;
  homeownerHelocTitle: string;
  homeownerModalHeaderBackgroundColor: string;
  homeownerModalHeaderTextColor: string;
  homeownerModalInputUnderlineColor: string;
  homeownerModalResetTextColor: string;
  homeownerModalResultValueBackgroundColor: string;
  homeownerRefiBtnBg: string;
  homeownerRefiBtnTxt: string;
  homeOwnerRefiChartBarHoverColor: string;
  homeownerRefiDataColor: string;
  homepageDarkBackground: string;
  homepageDarkBgTextColor: string;
  inputTextColor: string;
  legendColor: string;
  lenderHomePageBackgroundColor: string;
  linkColor: string;
  loanOfficerBg: string;
  loanRefiCalcBg: string;
  loanRefiCalcFontColor: string;
  loDirectAuthBackgroundColor: string;
  loDirectAuthButtonColor: string;
  loDirectAuthButtonTextColor: string;
  loDirectAuthTextColor: string;
  loDirectAuthLinkColor: string;
  logoUrl: string | null;
  lotMapNullStateImageUrl: string;
  manageAlertsLinkColor: string;
  manageHomesCardHeaderBackground: string;
  manageHomesCardHeaderTextColor: string;
  mapPinsColor: string;
  mapPinsVisitedColor: string;
  mapPlaceBoundaryLayerLineColor: string;
  mlsModalCTAColor: string;
  mlsRegistrationBorderColor: string;
  mlsRegistrationHoverColor: string;
  mobileActiveNavItemBottomBorderStyle: string;
  mobileActiveNavItemColor: string;
  mobileFiltersShowResultsButtonBackground: string;
  mobileInactiveNavItemColor: string;
  mobileLogoUrl: string | null;
  modalBorderRadius: string;
  mortgageCalculatorInputTextColor: string;
  mortgagePaymentBackground: string;
  neighborhoodInsightsBarColorOne: string;
  neighborhoodInsightsBarColorTwo: string;
  newIconBg: string;
  newIconTextColor: string;
  partialHeaderNavItemColor: string;
  partialHeaderNavItemHoverColor: string;
  partialHeaderChevronIconPositionOffset: string;
  partialHeaderHoverChevronIconColor: string;
  partialHeaderChevronIconColor: string;
  partialHeaderNavItemWidth: string;
  partialHeaderChevronIconHeight: string;
  paperAirplaneIconColor: string;
  pdpLotMapLotIndicatorColor: string;
  pdpLotMapTooltipColor: string;
  photosModalTopBarHeight: string;
  photoModalSelectedBorderColor: string;
  pieChartPrimaryColor: string;
  pillButtonDeemphasizedBackgroundColor: string;
  pillButtonDeemphasizedTextColor: string;
  pillButtonPrimaryBackgroundColor: string;
  pillButtonPrimaryButtonBorderRadius: string;
  pillButtonPrimaryTextColor: string;
  pillButtonSecondaryBackgroundColor: string;
  pillButtonSecondaryButtonBorderRadius: string;
  pillButtonSecondaryTextColor: string;
  pillButtonTertiaryBackgroundColor: string;
  pillButtonTertiaryTextColor: string;
  primaryButtonBorderRadius: string;
  primaryButtonFillColor: string;
  progressBarColor: string;
  propertyBrokerageLinkColor: string;
  propertyCardColor: string;
  propertyCardMinHeight: string;
  propertyInfoPDPMobileToggleColor: string;
  propertyInfoTableBackground: string;
  propertyIntroSeeAllDetailsLinkColor: string;
  propertyListingPhoneColor: string;
  regressionLineChartHomeScatterPlotColor: string;
  rentalYieldBackgroundColor: string;
  rentalYieldTextColor: string;
  resumeRecentSearchComponentBorderRadius: string;
  savedAndAlertsHeaderActiveItemUnderlineColor: string;
  saveSearchCTABackground: string;
  saveSearchCTABorderRadius: string;
  saveSearchDesktopButtonColor: string;
  saveSearchDesktopButtonFontWeight: number;
  saveSearchFloatingButtonColor: string;
  saveSearchFloatingButtonSuccessColor: string;
  searchListControlsColor: string;
  secondaryButtonBorderRadius: string;
  secondaryButtonFillColor: string;
  selectedSortOptionColor: string;
  supportUrl: string | null;
  textAreaBorder: string;
  textButtonLinkColor: string;
  tooltipIconColorOnHover: string;
  watchlistHeaderItemColor: string;
  welcomePageColor: string;
  savedSearchCardButtonStyle: savedSearchCardButtonStyle;
  lighterDisclosureAsteriskColor: string;
  CHOLoanOfficerPDPContactLOLinkColor: string;
  MobileSearchHomesBackButtonTextColor: string;
  partnerAgentBannerGradientColor: string;
  brokerageInfoPageBgColorFilter: string;
  partnerAgentCardGradientColor: string;
  partnerAgentBackgroundColor: string;
  pillButtonCustomButtonMinWidth: string;
  pillButtonCustomButtonWidth: string;
};

export type EmptyDiv = 'div';
export type MarkerImageIdMapping = { [imageId: string]: string };
export type ComehomeRedirectUtilInputType = {
  campaign: string;
  cobrandId: string;
  medium: string | null;
};
export type CobrandId = (typeof COBRAND_IDS)[keyof typeof COBRAND_IDS];

export type HomepageCustomizations = {
  homebuyingHeaderText: string | null;
  homebuyingSubheaderText: string | null;
  homebuyingSearchTabLabel: string | null;
  homebuyingSearchInputPlaceholder: string | null;

  homeownerHeaderText: string | null;
  homeownerSubheaderText: string | null;
  homeownerSearchTabLabel: string | null;
  homeownerSearchInputPlaceholder: string | null;

  homebuyingCtaHeader: string | null;
  homebuyingCtaDescription: string | null;
  homebuyingCtaButtonText: string | null;

  homeownerCtaHeader: string | null;
  homeownerCtaDescription: string | null;
  homeownerCtaButtonText: string | null;
};
